import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";

const LastCompositionsItem = (props) => {
    return (
        <ListGroup.Item>
            <NavLink
                to={
                    "/compositions/" +
                    props.composition.author.slug +
                    "/" +
                    props.composition.slug
                }
            >
                {props.composition.author.name +
                    " " +
                    props.composition.author.surname +
                    " – " +
                    props.composition.name}
            </NavLink>
        </ListGroup.Item>
    );
};

const LastCompositions = (props) => {
    return (
        <>
            <h6 className="mt-5">Останні публікації</h6>
            <ListGroup variant="flush">
                {props.lastCompositions.map((composition) => (
                    <LastCompositionsItem
                        key={
                            "lastComposition-" +
                            composition.author.id +
                            "-" +
                            composition.id
                        }
                        composition={composition}
                    />
                ))}
            </ListGroup>
        </>
    );
};

export default LastCompositions;
