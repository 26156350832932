import {authorsAPI} from "../api/api";
import noImage from "../no_image.jpg";

const SET_AUTHORS = "SET_AUTHORS";
const SET_AUTHOR = "SET_AUTHOR";
const SET_FOLKS = "SET_FOLKS";
const SET_IMAGE = "SET_IMAGE";

let initialState = {
    authors: null,
    author: null,
    folks: null,
    image: null,
};

const authorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHORS: {
            return { ...state, authors: action.authors };
        }
        case SET_AUTHOR: {
            return { ...state, author: action.author };
        }
        case SET_FOLKS: {
            return { ...state, folks: action.folks };
        }
        case SET_IMAGE: {
            return { ...state, image: action.image };
        }
        default:
            return state;
    }
};

export const setAuthors = (authors) => ({
    type: SET_AUTHORS,
    authors,
});

export const setAuthor = (author) => ({
    type: SET_AUTHOR,
    author,
});

export const setFolks = (folks) => ({
    type: SET_FOLKS,
    folks,
});

export const setImage = (image) => ({
    type: SET_IMAGE,
    image,
});

export const getAuthors = () => (dispatch) => {
    authorsAPI.getAuthors().then((response) => {
        dispatch(setAuthors(response.data));
    });
};

export const getAuthorBySlug = (slug) => (dispatch) => {
    dispatch(setAuthor(null));
    authorsAPI.getAuthorBySlug(slug).then((response) => {
        dispatch(setAuthor(response.data));
    });
};

export const getFolks = () => (dispatch) => {
    authorsAPI.getFolks().then((response) => {
        dispatch(setFolks(response.data));
    });
};

export const getImage = (author) => (dispatch) => {
    function checkIfImageExists(url, callback) {
        const img = new Image();

        img.src = url;

        if (img.complete) {
            callback(true);
        } else {
            img.onload = () => {
                callback(true);
            };

            img.onerror = () => {
                callback(false);
            };
        }
    }
    
    let imageUrl =
        "https://accordion.org.ua/public/storage/" +
        author.parent.slug +
        "/" +
        author.slug +
        "/photo.jpg";

    checkIfImageExists(imageUrl, (exists) => {
        if (exists) {
            dispatch(setImage(imageUrl));
        } else {
            dispatch(setImage(noImage));
        }
    });
}

export default authorsReducer;
