import MenuAuthor from "./MenuAuthor";
import NavDropdown from "react-bootstrap/NavDropdown";

const MenuLetter = (props) => {
    return (
        <NavDropdown title={props.letter.name}>
            {props.letter.childs.map((author) => (
                <MenuAuthor
                    key={author.id}
                    author={author}
                    setExpanded={props.setExpanded}
                />
            ))}
        </NavDropdown>
    );
};

export default MenuLetter;
