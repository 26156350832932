import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import authorsReducer from "./authors-reducer";
import categoriesReducer from "./categories-reducer";
import collectionsReducer from "./collections-reducer";
import compositionsReducer from "./compositions-reducer";
import filesReducer from "./files-reducer";
// import { reducer as formReducer } from "redux-form";

let reducers = combineReducers({
    authors: authorsReducer,
    categories: categoriesReducer,
    collections: collectionsReducer,
    compositions: compositionsReducer,
    files: filesReducer,
    // form: formReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
