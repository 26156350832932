import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompositionBySlug } from "../../../redux/compositions-reducer";
import Preloader from "../../Preloader/Preloader";
import ListGroup from "react-bootstrap/ListGroup";
import Ratio from "react-bootstrap/Ratio";
import { FaUserTie, FaArrowDown, FaTags } from "react-icons/fa";
import { AiFillFileZip } from "react-icons/ai";

const File = (props) => {
    return (
        <>
            <hr />
            <h4>
                {props.file.author.slug !== "noauthor" &&
                    props.file.author.name +
                        " " +
                        props.file.author.surname +
                        " – "}
                {props.file.name}
            </h4>
            {props.file.author.name_translate && (
                <div className="lead mb-2">
                    {props.file.author.name_translate}{" "}
                    {props.file.author.surname_translate}
                    {" – "}
                    {props.file.name_translate}
                </div>
            )}
            {props.file.collection && (
                <p>
                    <AiFillFileZip className="me-2" />
                    Збірник: <NavLink to={'/collections/' + props.file.collection.slug}>{props.file.collection.name}</NavLink>
                </p>
            )}
            {props.file.categories.length > 0 && (
                <p>
                    <FaTags className="me-2" />
                    Категорії:{" "}
                    {props.file.categories.map((category) => (
                        <NavLink
                            key={category.id}
                            className="btn btn-sm btn-primary me-1"
                            role="button"
                            to={"/categories/" + category.slug}
                        >
                            {category.name}
                        </NavLink>
                    ))}
                </p>
            )}
            {props.file.link && (
                <Ratio aspectRatio="1x1" className="mb-3">
                    <iframe
                        title={props.file.id}
                        src={props.file.link}
                        allowFullScreen
                    ></iframe>
                </Ratio>
            )}
            {props.file.video && (
                <Ratio aspectRatio="16x9" className="mb-3">
                    <iframe
                        title={props.file.id}
                        className="embed-responsive-item"
                        src={
                            "https://www.youtube.com/embed/" + props.file.video
                        }
                        allowFullScreen
                    ></iframe>
                </Ratio>
            )}
        </>
    );
};

const Composition = (props) => {
    return (
        <>
            <h1>
                {props.composition.author.slug !== "noauthor" &&
                    props.composition.author.name +
                        " " +
                        props.composition.author.surname +
                        " – "}
                {props.composition.name}
            </h1>
            {props.composition.author.name_translate && (
                <div className="lead">
                    {props.composition.author.name_translate}{" "}
                    {props.composition.author.surname_translate}
                    {" – "}
                    {props.composition.name_translate}
                </div>
            )}
            {props.composition.author.slug !== "noauthor" && (
                <p className="my-3">
                    <FaUserTie className="me-2" /> Автор:{" "}
                    <NavLink to={"/authors/" + props.composition.author.slug}>
                        {props.composition.author.name}{" "}
                        {props.composition.author.surname}
                    </NavLink>
                </p>
            )}
            <ListGroup variant="flush">
                <ListGroup.Item variant="dark">
                    <h5 className="pt-2">
                        Файли <FaArrowDown />
                    </h5>
                </ListGroup.Item>
            </ListGroup>
            {props.composition.files.map((file) => (
                <File key={file.id} file={file} />
            ))}
        </>
    );
};

const CompositionsContainer = () => {
    let { authorSlug, compositionSlug } = useParams();
    const composition = useSelector((state) => state.compositions.composition);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompositionBySlug(authorSlug, compositionSlug));
    }, [dispatch, authorSlug, compositionSlug]);

    if (composition) {
        return <Composition composition={composition} />;
    } else {
        return <Preloader />;
    }
};

export default CompositionsContainer;
