import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
    return (
        <footer className="footer py-5 bg-black text-white-50">
            <div className="container">
                <div className="row px-0">
                    <div className="col-sm-4">
                        <h6 className="text-white">Об'єм бібліотеки</h6>
                        <p>На сьогодні завантажено творів:</p>
                        <p className="display-3">
                            <strong>{props.compositionsCount}</strong>
                        </p>
                        <p>
                            Тематичних збірників із нотами:{" "}
                            <strong>{props.collectionsCount}</strong>
                            <br />
                            Загальна кількість PDF-файлів:{" "}
                            <strong>{props.filesCount}</strong>
                        </p>
                    </div>
                    <div className="col-sm-8">
                        <h6 className="text-white">Увага!</h6>
                        <p>
                            Ноти викладено ВИКЛЮЧНО для ознайомлення. Для
                            постійного користування придбайте, будь ласка,
                            паперовий примірник збірника.
                        </p>
                        <p>
                            Якщо викладені тут ноти чи збірники порушують Ваше
                            авторське право, напишіть нам на адресу
                            admin@accordion.org.ua або за допомогою форми на
                            головній сторінці.
                        </p>
                        <NavLink to="/privacy">Політика конфіденційності</NavLink>
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <hr />
                <span>© 2017-2022 Ноти для баяна та акордеона</span>
            </div>
        </footer>
    );
};

export default Footer;