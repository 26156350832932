import * as axios from "axios";

const instance = axios.create({
    baseURL: "https://node.ivaniura.org.ua/api/",
});

export const authorsAPI = {
    getAuthors() {
        return instance.get(`authors`);
    },
    getAuthorBySlug(slug) {
        return instance.get(`authors/slug/${slug}`);
    },
    getFolks() {
        return instance.get(`authors/folks`);
    },
};

export const categoriesAPI = {
    getCategories() {
        return instance.get(`categories`);
    },
    getCategoryBySlug(slug) {
        return instance.get(`categories/slug/${slug}`);
    },
};

export const collectionsAPI = {
    getCollections() {
        return instance.get(`collections`);
    },
    getCountOfCollections() {
        return instance.get(`collections/count`);
    },
    getCollectionBySlug(slug) {
        return instance.get(`collections/slug/${slug}`);
    },
};

export const compositionsAPI = {
    getCountOfCompositions() {
        return instance.get(`compositions/count`);
    },
    getLastCompositions() {
        return instance.get(`compositions/last`);
    },
    getCompositionBySlug(authorSlug, compositionSlug) {
        return instance.get(`compositions/${authorSlug}/${compositionSlug}`);
    },
};

export const filesAPI = {
    getCountOfFiles() {
        return instance.get(`files/count`);
    },
};
