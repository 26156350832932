import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionBySlug } from "../../../redux/collections-reducer";
import Preloader from "../../Preloader/Preloader";
import Ratio from "react-bootstrap/Ratio";

const OneCollection = (props) => {
    return (
        <>
            <h1>{props.collection.name}</h1>
            {props.collection.link && (
                <Ratio aspectRatio="1x1" className="mb-3">
                    <iframe
                        title={props.collection.id}
                        src={props.collection.link}
                        allowFullScreen
                    ></iframe>
                </Ratio>
            )}
            {props.collection.content && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.collection.content,
                    }}
                />
            )}
        </>
    );
};

const OneCollectionContainer = () => {
    let { slug } = useParams();
    const collection = useSelector((state) => state.collections.collection);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCollectionBySlug(slug));
    }, [dispatch, slug]);

    if (collection) {
        return <OneCollection collection={collection} />;
    } else {
        return <Preloader />;
    }
};

export default OneCollectionContainer;
