import React from "react";
import { connect } from "react-redux";

const MainPage = (props) => {
    return (
        <div>
            <h1>Про сайт</h1>
            <h5 className="text-center">
                Вітаємо всіх баяністів та акордеоністів в онлайн-бібліотеці
            </h5>
            <h2 className="text-center">«Ноти для баяна та акордеона».</h2>
            <p>
                Тут Ви можете знайти ноти для баяна та акордеона на свій смак.
                Також ми будемо щиро вдячні, якщо Ви допоможете нам у розвитку
                сайту.
            </p>
            <p>
                Що потрібно від Вас? Зовсім небагато – просто поширювати
                сторінку, з якої Ви скачали ноти, у своїх соцмережах (кнопочки
                для поширення внизу під кожними нотами).
            </p>
            <p>
                Якщо ж Ви не знайшли нот, які шукали, на даному сайті, але
                знайшли деінде, то можете допомогти проекту, надіславши ноти нам
                на електронну пошту, і вони обов’язково з’являться у нашій
                бібліотеці. Спеціально для цього нижче розташована контактна
                форма. За допомогою неї Ви й зможете надіслати нам ноти, яких
                немає на сайті.
            </p>
            <h3>Чому ми?</h3>
            <p>
                Кожен твір, який публікується, ми ретельно перевіряємо, якщо
                потрібно, робимо графічну корекцію, прибираємо написи, коригуємо
                розміщення нотних рядків, щоб ноти були розташовані максимально
                зручно. Такої ретельної підготовки нот немає на жодному іншому
                сайті з нотами для баяна та акордеона. Також усі файли PDF
                підлаштовані під друк на аркушах формату A4, тому у Вас ніколи
                не виникне проблем із друком.
            </p>
            <p>
                PDF-файли фізично розміщені на Google-диску, на даному сайті
                вбудовуються за допомогою <strong>iframe</strong>. Якщо на
                сторінці розміщено декілька PDF-файлів, то може спостерігатися
                "підвисання" сторінки, зберігайте терпіння 😉
            </p>
            <h4 className="text-center">
                Дякуємо всім, хто приділяє увагу сайту
                <br />
                «Ноти для баяна та акордеона»!
            </h4>
        </div>
    );
};

class MainPageContainer extends React.Component {
    componentDidMount() {}
    render() {
        return <MainPage />;
    }
}

let mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MainPageContainer);
