import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { initComponent } from "./init/init";
import SiteHeader from "./components/SiteHeader/SiteHeader";
import SiteMenu from "./components/SiteMenu/SiteMenu";
import MainPageContainer from "./components/content/MainPage/MainPage";
import { Routes, Route } from "react-router-dom";
import SidebarCategories from "./components/Sidebar/SidebarCategories";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AuthorsContainer from "./components/content/Authors/Authors";
import CategoriesContainer from "./components/content/Categories/Categories";
import CollectionsContainer from "./components/content/Collections/Collections";
import OneCollectionContainer from "./components/content/OneCollection/OneCollection";
import CompositionsContainer from "./components/content/Compositions/Compositions";
import Footer from "./components/Footer/Footer";
import Privacy from "./components/content/Privacy/Privacy";
import LastCompositions from "./components/Sidebar/LastCompositions";

const App = (props) => {
    return (
        <div className="App">
            <SiteHeader />
            <SiteMenu authors={props.authors} />
            <Container className="pt-5">
                <Row>
                    <Col lg={8} className="mb-5">
                        <Routes>
                            <Route path="/" element={<MainPageContainer />} />
                            <Route
                                path="/authors/:slug"
                                element={<AuthorsContainer />}
                            />
                            <Route
                                path="/categories/:slug"
                                element={<CategoriesContainer />}
                            />
                            <Route
                                path="/collections"
                                element={<CollectionsContainer />}
                            />
                            <Route
                                path="/collections/:slug"
                                element={<OneCollectionContainer />}
                            />
                            <Route
                                path="/compositions/:authorSlug/:compositionSlug"
                                element={<CompositionsContainer />}
                            />
                            <Route path="/privacy" element={<Privacy />} />
                        </Routes>
                    </Col>
                    <Col lg={4} className="sidebar mb-5">
                        <SidebarCategories
                            categories={props.categories}
                            folks={props.folks}
                        />
                        <LastCompositions
                            lastCompositions={props.lastCompositions}
                        />
                    </Col>
                </Row>
            </Container>
            <Footer
                collectionsCount={props.collectionsCount}
                compositionsCount={props.compositionsCount}
                filesCount={props.filesCount}
            />
        </div>
    );
};

export default initComponent(App);
