import logo from "../../accordion.jpg";
import { ParallaxBanner } from "react-scroll-parallax";
import "./SiteHeader.css";
import { NavLink } from "react-router-dom";

const SiteHeader = () => {
    return (
        <ParallaxBanner
            className="parallax"
            layers={[
                { image: logo, speed: -20 },
                {
                    speed: -15,
                    children: (
                        <div className="sitename">
                            <div className="bg-black text-white">
                                <NavLink to="/">
                                    Ноти для баяна та акордеона
                                </NavLink>
                                <hr />
                                <p>
                                    Безкоштовно скачати ноти для баяна та
                                    акордеона
                                </p>
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    );
};

export default SiteHeader;
