import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCollections } from "../../../redux/collections-reducer";
import Preloader from "../../Preloader/Preloader";
import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";

const Collections = (props) => {
    return (
        <>
            <h1>Збірники</h1>
            <ListGroup>
                {props.collections.map((collection) => (
                    <ListGroup.Item key={collection.id}>
                        <NavLink to={"/collections/" + collection.slug}>
                            {collection.name}
                        </NavLink>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    );
};

const CollectionsContainer = () => {
    const collections = useSelector((state) => state.collections.collections);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCollections());
    }, [dispatch]);

    if (collections) {
        return <Collections collections={collections} />;
    } else {
        return <Preloader />;
    }
};

export default CollectionsContainer;
