import "./SidebarCategories.css";
import SidebarFolks from "./SidebarFolks";
import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";

const SidebarCategories = (props) => {
    return (
        <>
            <h6>Пошук по категоріях</h6>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <NavLink to={"/collections"}>Збірники</NavLink>
                </ListGroup.Item>
                <SidebarFolks folks={props.folks} />
                {props.categories.map((category) => (
                    <SidebarCategoriesItem
                        key={category.id}
                        category={category}
                    />
                ))}
            </ListGroup>
        </>
    );
};

const SidebarCategoriesItem = (props) => {
    return (
        <ListGroup.Item>
            <NavLink to={"/categories/" + props.category.slug}>
                {props.category.name}
            </NavLink>
        </ListGroup.Item>
    );
};

export default SidebarCategories;
