import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";

const MenuAuthor = (props) => {
    return (
        <NavDropdown.Item
            as={NavLink}
            to={`/authors/${props.author.slug}`}
            onClick={() => props.setExpanded(false)}
        >
            {`${props.author.surname} ${props.author.name}`}
        </NavDropdown.Item>
    );
};

export default MenuAuthor;
