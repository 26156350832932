import { compositionsAPI } from "../api/api";

const SET_COUNT_OF_COMPOSITIONS = "SET_COUNT_OF_COMPOSITIONS";
const SET_LAST_COMPOSITIONS = "SET_LAST_COMPOSITIONS";
const SET_COMPOSITION = "SET_COMPOSITION";

let initialState = {
    composition: null,
    last: null,
    count: null,
};

const compositionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNT_OF_COMPOSITIONS: {
            return { ...state, count: action.count };
        }
        case SET_LAST_COMPOSITIONS: {
            return { ...state, last: action.last };
        }
        case SET_COMPOSITION: {
            return { ...state, composition: action.composition };
        }
        default:
            return state;
    }
};

export const setCountOfCompositions = (count) => ({
    type: SET_COUNT_OF_COMPOSITIONS,
    count,
});

export const setLastCompositions = (last) => ({
    type: SET_LAST_COMPOSITIONS,
    last,
});

export const setComposition = (composition) => ({
    type: SET_COMPOSITION,
    composition,
});

export const getCountOfCompositions = () => async (dispatch) => {
    compositionsAPI.getCountOfCompositions().then((response) => {
        dispatch(setCountOfCompositions(response.data.compositions));
    });
}

export const getLastCompositions = () => async (dispatch) => {
    compositionsAPI.getLastCompositions().then((response) => {
        dispatch(setLastCompositions(response.data));
    });
};

export const getCompositionBySlug =
    (authorSlug, compositionSlug) => async (dispatch) => {
        compositionsAPI
            .getCompositionBySlug(authorSlug, compositionSlug)
            .then((response) => {
                dispatch(setComposition(response.data));
            });
    };

export default compositionsReducer;
