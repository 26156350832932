import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorBySlug, getImage } from "../../../redux/authors-reducer";
import Preloader from "../../Preloader/Preloader";
import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";
import ModalPDF from "../../ModalPDF/ModalPDF";
import Button from "react-bootstrap/Button";

const CompositionModal = (props) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <ListGroup.Item as="li" action>
                <NavLink to={""} onClick={() => setModalShow(true)}>
                    {props.composition.name}
                    {props.composition.name_translate &&
                        " (" + props.composition.name_translate + ")"}
                </NavLink>
            </ListGroup.Item>
            <ModalPDF
                id={props.composition.id}
                name={props.composition.name}
                link={
                    "/compositions/" +
                    props.author_slug +
                    "/" +
                    props.composition.slug
                }
                iframeLink={props.composition.files[0].link}
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
        </>
    );
};

const Author = (props) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <h1>
                {props.author.name} {props.author.surname}
            </h1>
            {props.author.name_translate && (
                <div className="lead">
                    {props.author.name_translate}{" "}
                    {props.author.surname_translate}
                </div>
            )}
            {props.author.parent_id !== 1 && (
                <>
                    <div className="mb-5">
                        <Button
                            variant="primary"
                            onClick={() => setModalShow(true)}
                        >
                            Читати біографію
                        </Button>
                    </div>
                    <h3>Твори</h3>
                </>
            )}
            <ListGroup as="ol" numbered>
                {props.author.compositions.map((composition) => (
                    <CompositionModal
                        key={composition.id}
                        composition={composition}
                        author_slug={props.author.slug}
                    />
                ))}
            </ListGroup>
            <ModalPDF
                about={true}
                id={props.author.id}
                name={props.author.name + " " + props.author.surname}
                image={props.image}
                biography={props.author.biography}
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
        </>
    );
};

const AuthorsContainer = () => {
    let { slug } = useParams();
    const author = useSelector((state) => state.authors.author);
    const image = useSelector((state) => state.authors.image);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAuthorBySlug(slug));
    }, [dispatch, slug]);

    useEffect(() => {
        if (author) {
            dispatch(getImage(author));
        }
    }, [dispatch, author]);

    if (author && image) {
        return <Author author={author} image={image} />;
    } else {
        return <Preloader />;
    }
};

export default AuthorsContainer;
