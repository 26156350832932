import "./Preloader.css";
import Spinner from "react-bootstrap/Spinner";

const Preloader = () => {
    return (
        <div className="preloader">
            <Spinner animation="border" variant="dark" />
            <div>Секундочку, завантажуємо дані...</div>
        </div>
    );
};

export default Preloader;
