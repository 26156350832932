import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Ratio from "react-bootstrap/Ratio";

const ModalPDF = (props) => {
    return (
        <Modal
            size="xl"
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            aria-labelledby="props.id"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="props.id">{props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.iframeLink && (
                    <Ratio aspectRatio="21x9">
                        <iframe
                            title={props.id}
                            src={props.iframeLink}
                            allowFullScreen
                        ></iframe>
                    </Ratio>
                )}
                {props.image && (
                    <div className="text-center mb-3">
                        <img src={props.image} alt={props.name} />
                    </div>
                )}
                {props.about &&
                    (props.biography ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.biography,
                            }}
                        />
                    ) : (
                        <div>
                            Немає біографії даного автора. Якщо ви володієте
                            певною достовірною інформацією про нього, надішліть,
                            будь ласка, нам через форму зворотнього зв'язку на
                            головній сторінці. Дякуємо заздалегідь!
                        </div>
                    ))}
            </Modal.Body>
            {props.link && (
                <Modal.Footer>
                    <NavLink
                        className="btn btn-primary"
                        role="button"
                        to={props.link}
                    >
                        Дивитися повністю
                    </NavLink>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default ModalPDF;
