import { filesAPI } from "../api/api";

const SET_COUNT_OF_FILES = "SET_COUNT_OF_FILES";

let initialState = {
    count: null,
};

const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNT_OF_FILES: {
            return { ...state, count: action.count };
        }
        default:
            return state;
    }
};

export const setCountOfFiles = (count) => ({
    type: SET_COUNT_OF_FILES,
    count,
});

export const getCountOfFiles = () => async (dispatch) => {
    filesAPI.getCountOfFiles().then((response) => {
        dispatch(setCountOfFiles(response.data.files));
    });
}

export default filesReducer;
