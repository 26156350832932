import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../components/Preloader/Preloader";
import { getAuthors, getFolks } from "../redux/authors-reducer";
import { getCategories } from "../redux/categories-reducer";
import { getCountOfCollections } from "../redux/collections-reducer";
import { getCountOfCompositions, getLastCompositions } from "../redux/compositions-reducer";
import { getCountOfFiles } from "../redux/files-reducer";

export const initComponent = (Component) => {
    const InitComponent = () => {
        const authors = useSelector((state) => state.authors.authors);
        const folks = useSelector((state) => state.authors.folks);
        const categories = useSelector((state) => state.categories.categories);
        const collectionsCount = useSelector((state) => state.collections.count);
        const compositionsCount = useSelector((state) => state.compositions.count);
        const lastCompositions = useSelector(
            (state) => state.compositions.last
        );
        const filesCount = useSelector((state) => state.files.count);
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(getAuthors());
            dispatch(getFolks());
            dispatch(getCategories());
            dispatch(getLastCompositions());
            dispatch(getCountOfCollections());
            dispatch(getCountOfCompositions());
            dispatch(getCountOfFiles());
        }, [dispatch]);

        if (
            authors &&
            folks &&
            categories &&
            lastCompositions &&
            collectionsCount &&
            compositionsCount &&
            filesCount
        ) {
            return (
                <Component
                    authors={authors}
                    folks={folks}
                    categories={categories}
                    lastCompositions={lastCompositions}
                    collectionsCount={collectionsCount}
                    compositionsCount={compositionsCount}
                    filesCount={filesCount}
                />
            );
        } else {
            return <Preloader />;
        }
    };

    return InitComponent;
};
