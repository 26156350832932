import { categoriesAPI } from "../api/api";

const SET_CATEGORIES = "SET_CATEGORIES";
const SET_CATEGORY = "SET_CATEGORY";

let initialState = {
    categories: null,
    category: null,
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES: {
            return { ...state, categories: action.categories };
        }
        case SET_CATEGORY: {
            return { ...state, category: action.category };
        }
        default:
            return state;
    }
};

export const setCategories = (categories) => ({
    type: SET_CATEGORIES,
    categories,
});

export const setCategory = (category) => ({
    type: SET_CATEGORY,
    category,
});

export const getCategories = () => async (dispatch) => {
    categoriesAPI.getCategories().then((response) => {
        dispatch(setCategories(response.data));
    });
};

export const getCategoryBySlug = (slug) => async (dispatch) => {
    categoriesAPI.getCategoryBySlug(slug).then((response) => {
        dispatch(setCategory(response.data));
    });
};

export default categoriesReducer;
