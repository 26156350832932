import "./SidebarCategories.css";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";

const SidebarFolksItem = (props) => {
    return (
        <Dropdown.Item
            as={NavLink}
            to={`/authors/${props.folk.slug}`}
        >
            {props.folk.name}
        </Dropdown.Item>
    );
};

const SidebarFolks = (props) => {
    return (
        <ListGroup.Item as={Dropdown} drop={{ sm: "start" }}>
            <Dropdown.Toggle
                as={NavLink}
                to="#"
                id="folks"
                className="folks-dropleft"
            >
                Народні пісні і танці
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {props.folks.map((folk) => (
                    <SidebarFolksItem key={"folk-" + folk.id} folk={folk} />
                ))}
            </Dropdown.Menu>
        </ListGroup.Item>
    );
};

export default SidebarFolks;
