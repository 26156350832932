import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryBySlug } from "../../../redux/categories-reducer";
import Preloader from "../../Preloader/Preloader";
import ListGroup from "react-bootstrap/ListGroup";
import { NavLink } from "react-router-dom";
import ModalPDF from "../../ModalPDF/ModalPDF";

const Composition = (props) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <ListGroup.Item>
                <NavLink to={""} onClick={() => setModalShow(true)}>
                    {props.composition.author.surname}{" "}
                    {props.composition.author.name}
                    {" – "}
                    {props.composition.name}
                    {props.composition.name_translate &&
                        " (" + props.composition.name_translate + ")"}
                </NavLink>
            </ListGroup.Item>
            <ModalPDF
                id={props.composition.id}
                name={props.composition.name}
                link={
                    "/compositions/" +
                    props.composition.author.slug +
                    "/" +
                    props.composition.slug
                }
                iframeLink={props.composition.files[0].link}
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
        </>
    );
};

const Category = (props) => {
    return (
        <>
            <h1>{props.category.name}</h1>
            <ListGroup>
                {props.category.compositions.map((composition) => (
                    <Composition
                        key={composition.id}
                        composition={composition}
                    />
                ))}
            </ListGroup>
        </>
    );
};

const CategoriesContainer = () => {
    let { slug } = useParams();
    const category = useSelector((state) => state.categories.category);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryBySlug(slug));
    }, [dispatch, slug]);

    if (category) {
        return <Category category={category} />;
    } else {
        return <Preloader />;
    }
};

export default CategoriesContainer;
