const Privacy = () => {
    return (
        <>
            <h1>Політика конфіденційності</h1>
            <p>
                Даний сайт не збирає жодних даних про Вас, окрім Вашого імені та
                електронної адреси у випадку надсилання Вами листа через форму
                зворотного зв'язку на головній сторінці. Також запевняємо, що ці
                дані не будуть використані жодним іншим чином, окрім відповіді
                на Ваш електронний лист.
            </p>
            <p>
                З іншого боку, сайт використовує аналітику та рекламні банери
                третіх сторін, які можуть використовуватися ними для збирання
                певної інформації про Вас у цілях показу Вам цільової реклами та
                відстеження активності на сайті. Адміністрація сайту не несе
                жодної відповідальності за витік інформації про Вас через ці
                сервіси. Якщо Ви не бажаєте надавати жодної інформації про себе,
                Ви можете вимкнути у Вашому браузері всі засоби відстеження,
                такі як cookies, дані про місцезнаходження та інші.
            </p>
            <p>
                Продовжуючи користуватися цим веб-сайтом, Ви автоматично
                погоджуєтеся на умови, описані вище на даній сторінці.
            </p>
        </>
    );
}

export default Privacy;