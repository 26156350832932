import { collectionsAPI } from "../api/api";

const SET_COLLECTIONS = "SET_COLLECTIONS";
const SET_COUNT_OF_COLLECTIONS = "SET_COUNT_OF_COLLECTIONS";
const SET_COLLECTION = "SET_COLLECTION";

let initialState = {
    collections: null,
    collection: null,
    count: null,
};

const collectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COLLECTIONS: {
            return { ...state, collections: action.collections };
        }
        case SET_COUNT_OF_COLLECTIONS: {
            return { ...state, count: action.count };
        }
        case SET_COLLECTION: {
            return { ...state, collection: action.collection };
        }
        default:
            return state;
    }
};

export const setCollections = (collections) => ({
    type: SET_COLLECTIONS,
    collections,
});

export const setCountOfCollections = (count) => ({
    type: SET_COUNT_OF_COLLECTIONS,
    count,
});

export const setCollection = (collection) => ({
    type: SET_COLLECTION,
    collection,
});

export const getCollections = () => async (dispatch) => {
    collectionsAPI.getCollections().then((response) => {
        dispatch(setCollections(response.data));
    });
};

export const getCountOfCollections = () => async (dispatch) => {
    collectionsAPI.getCountOfCollections().then((response) => {
        dispatch(setCountOfCollections(response.data.collections));
    });
};

export const getCollectionBySlug = (slug) => async (dispatch) => {
    collectionsAPI.getCollectionBySlug(slug).then((response) => {
        dispatch(setCollection(response.data));
    });
};

export default collectionsReducer;
