import { useState } from "react";
import MenuLetter from "./MenuLetter";
import "./SiteMenu.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

const Menu = (props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar bg="black" variant="dark" expand="lg" expanded={expanded}>
            <Container>
                <Navbar.Toggle
                    aria-controls="navbarScroll"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="navbarScroll">
                    <Nav>
                        {props.authors.map((letter) =>
                            letter.childs.length ? (
                                <MenuLetter
                                    key={letter.id}
                                    letter={letter}
                                    setExpanded={setExpanded}
                                />
                            ) : (
                                false
                            )
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;
